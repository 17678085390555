<template>
  <CountrySwitchMenuButton
    :countryCode="thisMarket.countryCode"
    @click="() => (isVisible = true)"
  />
  <div
    v-if="isVisible"
    class="bg-surface-default-low fixed inset-0 flex flex-col"
  >
    <div
      class="border-static-default-low flex items-center border-b px-16 py-8"
      :class="{
        'h-52': !isNewNavbar,
      }"
    >
      <CountrySwitchMenuHeader
        :is-full-screen="true"
        @click="() => (isVisible = false)"
      />
    </div>
    <CountrySwitchMenuSearchList />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import CountrySwitchMenuButton from './CountrySwitchMenuButton.vue'
import CountrySwitchMenuHeader from './CountrySwitchMenuHeader.vue'
import CountrySwitchMenuSearchList from './CountrySwitchMenuSearchList.vue'

const experiments = useExperiments()
const isNewNavbar = experiments['experiment.newNavigation'] === 'new'

const thisMarket = useMarketplace().market
const isVisible = ref(false)
</script>
