/**
 * Determines if a given date falls within the Black Friday season.
 * The Black Friday season is defined as the period from November 4th to November 30th, inclusive.
 *
 * @param currentDate - A date object representing the current date in UTC timezone.
 * @returns Returns true if the date is between the 4th and 30th of November, inclusive; otherwise, returns false.
 *
 * Note: This function assumes the input date is in UTC. It does not handle other timezones.
 */
export function isBlackFridaySeason(currentDate: Date) {
  const currentYear = currentDate.getUTCFullYear()
  // Month is 0-indexed, so November is represented by 10
  const nov4th = new Date(Date.UTC(currentYear, 10, 4))
  const dec1st = new Date(Date.UTC(currentYear, 11, 1))

  return currentDate >= nov4th && currentDate < dec1st
}
