<template>
  <MegaMenu v-if="newNavbar" />
  <TopSalesBarMenu v-else />
</template>

<script setup lang="ts">
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import MegaMenu from './MegaMenu.vue'
import TopSalesBarMenu from './TopSalesBarMenu.vue'

const experiments = useExperiments()
const newNavbar = experiments['experiment.newNavigation'] === 'new'
</script>
