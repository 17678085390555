export default {
  storePanelMobilePrevious: {
    id: 'store_panel_mobile_previous',
    defaultMessage: 'Back',
  },
  showAllDepartments: {
    id: 'show_all_departments',
    defaultMessage: 'Show all departments',
  },
  linkTradeIn: {
    id: 'mweb_nav_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  linkAboutUs: {
    id: 'mweb_nav_to_about_us',
    defaultMessage: 'About us',
  },
  linkVerifiedRefurbished: {
    id: 'header_to_verified_refurbished',
    defaultMessage: 'Verified Refurbished',
  },
  linkBackStories: {
    id: 'header_to_back_stories',
    defaultMessage: 'Back Stories',
  },
  linkHelp: {
    id: 'mweb_nav_to_help',
    defaultMessage: 'Help',
  },
  shop: {
    id: 'mweb_nav_to_shop',
    defaultMessage: 'Shop',
  },
  goodDeals: {
    id: 'menu_good_deals',
    defaultMessage: 'Good deals',
  },
}
