import type { ObjectDirective } from 'vue'

interface ClickOutsideInterface {
  handler(event: Event): void
  callback(event: Event): void
}

interface VClickOutsideElement extends HTMLElement {
  clickOutsideDirective: ClickOutsideInterface
}

export const clickOutside: ObjectDirective<VClickOutsideElement> = {
  mounted(element, binding) {
    // Let's save the handler on the element itself so we can access it when unbinding the directive.
    // eslint-disable-next-line no-param-reassign
    element.clickOutsideDirective = {
      handler: (event: Event) => {
        if (element === event.target) return
        if (element.contains(event.target as Node)) return

        element.clickOutsideDirective.callback(event)
      },
      callback: binding.value,
    }

    document.body.addEventListener(
      'click',
      element.clickOutsideDirective.handler,
    )
    document.body.addEventListener(
      'touchstart',
      element.clickOutsideDirective.handler,
    )
  },
  unmounted(element) {
    if (!element.clickOutsideDirective) {
      return
    }

    document.body.removeEventListener(
      'click',
      element.clickOutsideDirective.handler,
    )

    document.body.removeEventListener(
      'touchstart',
      element.clickOutsideDirective.handler,
    )
  },
}
