import type { LinkInternal } from '../../api-models/LinkInternal'
import { createHttpEndpoint } from '../../utils'

export type TopSale = {
  title: string
  title_display: string
  link: LinkInternal
  style: {
    text_color: string
  }
}

/**
 * Returns the topsales navigation

 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/navigation-api/definition#tag/topsales/operation/getNavigationTopsalesV2}
 */
export const getTopsales = createHttpEndpoint<TopSale[]>({
  method: 'GET',
  operationId: 'getNavigationTopsalesV2',
  path: '/bm/navigation/v2/topsales',
})
