<template>
  <div class="flex-1">
    <RevButtonRounded
      v-if="isFullScreen"
      :icon="IconArrowLeft"
      variant="secondary"
      @click="$emit('click')"
    />
  </div>
  <div class="grow">
    <h1 class="body-1-bold text-center">
      {{ i18n(translations.title) }}
    </h1>
  </div>
  <div class="flex flex-1 justify-end">
    <RevButtonRounded
      class="cursor-pointer"
      :icon="IconCross"
      variant="secondary"
      @click="handleHide"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import { useNavigation } from '../../composables/useNavigation'

import translations from './CountrySwitch.translations'

withDefaults(
  defineProps<{
    isFullScreen?: boolean
  }>(),
  { isFullScreen: false },
)
const i18n = useI18n()
const emit = defineEmits(['click'])
const { toggle } = useNavigation()

function handleHide() {
  emit('click')
  toggle()
}
</script>
