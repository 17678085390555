import type { Locale, MarketCountryCode } from '@backmarket/http-api'

export function countryDisplayName(
  locale: Locale,
  countryCode: MarketCountryCode,
) {
  const localePrefix = locale.split('_')[0]
  const language = new Intl.DisplayNames([localePrefix], {
    type: 'language',
  }).of(localePrefix)

  return `${language} (${countryCode})`
}
