<template>
  <div v-if="menu" class="text-action-default-hi z-[1] flex h-full flex-row">
    <div
      class="flex w-72 flex-auto items-center py-4 no-underline md:w-56"
      :style="{ background: menu.display.color }"
    >
      {{ menu.display.label }}
    </div>
    <RevLink
      class="flex flex-auto items-center px-8 no-underline outline-none hover:bg-action-default-low focus:bg-action-default-low md:py-4"
      :to="menu.bo"
      :underlined="false"
    >
      BO Merchant
    </RevLink>

    <RevLink
      v-if="menu.admin"
      class="flex flex-auto items-center px-8 no-underline outline-none hover:bg-action-default-low focus:bg-action-default-low md:py-4"
      :to="{ name: AdminRoutes.HOME }"
      :underlined="false"
    >
      Admin
    </RevLink>

    <RevButtonBase
      class="flex flex-auto items-center px-8 outline-none hover:bg-action-default-low focus:bg-action-default-low md:py-4"
      data-test="logout-button"
      @click="logout"
    >
      Logout
    </RevButtonBase>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { useLogout } from '@backmarket/nuxt-layer-oauth/useLogout'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLink } from '@ds/components/Link'
import { storeToRefs } from 'pinia'

import { AdminRoutes } from '~/scopes/admin/routes'

const { logout } = useLogout()

const userStore = useUserStore()

const { user } = storeToRefs(userStore)

const MENU = {
  admin: {
    type: 'admin',
    bo: '/bo_merchant/admin_tools/dashboard',
    admin: true,
    display: {
      label: 'Logged as admin',
      color: '#FD595E',
    },
  },
  client: {
    type: 'client',
    bo: '/bo_merchant/admin_tools/dashboard/?cid=s',
    admin: false,
    display: {
      label: 'Logged as client',
      color: '#28BC83',
    },
  },
  merchant: {
    type: 'merchant',
    bo: '/bo_merchant/admin_tools/merchants/?mid=s',
    admin: false,
    display: {
      label: 'Logged as merchant',
      color: '#FFA500',
    },
  },
}

const menu = computed(() => {
  if (user.value.isStaff) {
    return MENU.admin
  }

  if (user.value.isShadowingClient) {
    return MENU.client
  }

  if (user.value.isShadowingMerchant) {
    return MENU.merchant
  }

  return null
})
</script>
