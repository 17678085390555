<template>
  <div>
    <NavMenu class="hidden lg:block" />
    <NavBurgerMenu v-if="isOpen" class="lg:hidden" />
  </div>
</template>

<script setup lang="ts">
import { useNavigation } from '../composables/useNavigation'

import NavBurgerMenu from './NavBurgerMenu/NavBurgerMenu.vue'
import NavMenu from './NavMenu/NavMenu.vue'

const { isOpen } = useNavigation()
</script>
