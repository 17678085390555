<template>
  <div translate="no">
    <span aria-label="copyright" role="img"> © </span>

    {{ year }} {{ COMPANY_NAME }}
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

const runtimeConfig = useRuntimeConfig()

const { COMPANY_NAME } = runtimeConfig.public

const year = new Date().getFullYear()
</script>
