<template>
  <section
    v-if="services.length"
    class="flex flex-col px-24 pb-16 pt-24 lg:gap-16 lg:px-40 lg:py-24"
  >
    <h2 class="text-static-default-mid body-2">
      {{ i18n(translations.goodToKnow) }}
    </h2>
    <ul class="flex flex-col gap-12 py-12 lg:py-0">
      <li v-for="service in services" :key="service?.title">
        <NavMenuService
          v-if="service?.title && service?.description"
          class="w-full"
          :description="service.description"
          :icon="service.icon"
          :title="service.title"
        />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { type Component } from 'vue'

import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import NavMenuService from '../NavMenu/NavMenuService.vue'

import translations from './Services.translations'

const {
  features,
}: {
  features: { buyback: { enabled: boolean }; mobilePlan: { enabled: boolean } }
} = useMarketplace()

const props = defineProps<{ plpId: string }>()

const i18n = useI18n()
const logger = useLogger()

const t: Record<string, I18nDefinition | undefined> = translations

function getTranslation(prefix: string, suffix: string) {
  const key = `${prefix}_${suffix.toLowerCase()}`
  const translation = t[key]

  return { message: translation ? i18n(translation) : undefined, key }
}

const services: Array<{
  title: string
  description: string
  icon: Component
}> = []

function addService(titleKey: string, descriptionKey: string, icon: Component) {
  if (!props.plpId) {
    logger.error(
      '[megamenu] Unable to get the id of the plp to compute the lokalise key of the service',
    )

    return
  }

  const title = getTranslation(titleKey, props.plpId)
  const description = getTranslation(descriptionKey, props.plpId)

  if (title?.message && description?.message) {
    services.push({
      title: title.message,
      description: description.message,
      icon,
    })
  } else {
    logger.info(
      `[megamenu] missing translation for ${title.key} or ${description.key}`,
    )
  }
}

if (features.buyback.enabled) {
  addService('tradeInTitle', 'tradeInDescription', IconSwap)
}

if (features.mobilePlan.enabled) {
  addService('mobilePlanTitle', 'mobilePlanDescription', IconSparkles)
}
</script>
