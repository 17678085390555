import type { Link } from '@backmarket/http-api'

import { createHttpEndpoint } from '../../utils'

export type MenuItem = {
  id: number
  title: string
  link?: Link
  alt?: string
  children: MenuItem[] | never[]
  style?: {
    text_color?: string
  }
  image?: string
}

/**
 * Returns the sidebar navigation
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/navigation-api/definition#tag/sidebar/operation/getNavigationSidebarV2}
 */
export const getSidebar = createHttpEndpoint<MenuItem[]>({
  method: 'GET',
  operationId: 'navigationFetch',
  path: '/bm/navigation/v2/sidebar',
})
