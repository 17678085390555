<template>
  <RevButtonBase @click="toggle">
    <IconBurger />
  </RevButtonBase>
</template>

<script setup lang="ts">
import { RevButtonBase } from '@ds/components/ButtonBase'
import { IconBurger } from '@ds/icons/IconBurger'

import { useNavigation } from '../composables/useNavigation'

const { toggle } = useNavigation()
</script>
