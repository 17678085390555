<template>
  <div class="h-56 py-8">
    <div
      class="flex flex-nowrap motion-safe:duration-300 motion-safe:ease-linear"
      :class="{
        'motion-safe:-translate-x-full': isSearchOpened,
      }"
    >
      <div
        class="flex w-full shrink-0 flex-nowrap items-center gap-8 pl-24 pr-12"
      >
        <TheLogo class="h-16 grow" img-auto-width />

        <ul class="flex items-center justify-end">
          <li class="flex size-40 items-center justify-center">
            <button :aria-label="i18n(translations.search)" @click="openSearch">
              <IconSearch size="24" />
            </button>
          </li>
          <li class="flex size-40 items-center justify-center">
            <UserIcon />
          </li>
          <li class="flex size-40 items-center justify-center">
            <CartIconAndBubble data-test="icon-cart" />
          </li>
          <li class="flex size-40 items-center justify-center">
            <BurgerMenuIcon />
          </li>
        </ul>
      </div>

      <div
        class="w-full shrink-0 items-center gap-8 px-12"
        :class="{
          flex: isSearchOpened,
          hidden: !isSearchOpened,
        }"
      >
        <RevButtonRounded
          :alternative-text="i18n(translations.back)"
          :icon="IconArrowLeft"
          variant="secondary"
          @click="closeSearch"
        />
        <SearchBar
          class="grow"
          fullscreen
          :should-focus-input="shouldFocusSearch"
          @submit="closeSearch"
        />
      </div>
    </div>

    <MenuFullscreen v-show="burgerMenuState === 'open'" class="z-20" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconSearch } from '@ds/icons/IconSearch'

import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import { useNavigation } from '../composables/useNavigation'

import BurgerMenuIcon from './BurgerMenuIcon.vue'
import CartIconAndBubble from './CartIconAndBubble.vue'
import translations from './HeaderBarSmallScreen.translations'
import MenuFullscreen from './MenuFullscreen.vue'
import TheLogo from './TheLogo.vue'
import UserIcon from './UserIcon.vue'

const animationDuration = 300
const { state: burgerMenuState } = useNavigation()
const isSearchOpened = ref(false)
const shouldFocusSearch = ref(false)
const i18n = useI18n()

function awaitEndOfAnimation() {
  setTimeout(() => {
    shouldFocusSearch.value = true
  }, animationDuration)
}

function openSearch() {
  isSearchOpened.value = true
  awaitEndOfAnimation()
  window.document.body.style.overflow = 'hidden'
  window.document.body.style.height = '100%'
}
function closeSearch() {
  isSearchOpened.value = false
  shouldFocusSearch.value = false
  window.document.body.style.overflow = ''
  window.document.body.style.height = ''
}
</script>
