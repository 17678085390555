<template>
  <section
    class="border-static-default-low flex items-center border-b px-16 py-8"
  >
    <div class="flex-1">
      <RevButtonRounded
        v-if="title"
        :icon="IconArrowLeft"
        variant="secondary"
        @click="() => clearActiveMenu()"
      />
    </div>

    <div>
      <div
        v-if="title"
        class="heading-3 grow text-center"
        :style="{ color: color }"
      >
        {{ title }}
      </div>

      <TheLogo v-else class="flex flex-1 justify-center" />
    </div>

    <div class="flex flex-1 justify-end">
      <RevButtonRounded
        :icon="IconCross"
        variant="secondary"
        @click="() => toggle()"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import TheLogo from '~/scopes/navigation/components/TheLogo.vue'

import { useNavigation } from '../../composables/useNavigation'

const { toggle, clearActiveMenu } = useNavigation()

defineProps<{ title?: string; color?: string }>()
</script>
