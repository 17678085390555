<template>
  <section
    class="mt-8 flex flex-col gap-16 p-24"
    :class="{ 'pb-8': FF_ENABLE_COUNTRY_SWITCH }"
  >
    <RevLink
      v-for="link in links.filter(({ name }) => name !== 'footer_buyback')"
      :key="link.name"
      class="body-2-bold"
      :to="link.href"
      :tracking="{ zone: 'nav', name: link.name }"
      :underlined="false"
    >
      {{ link.label }}
    </RevLink>
  </section>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'

import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../../composables/useNavLinks'

const { links } = useNavLinks()
const { FF_ENABLE_COUNTRY_SWITCH } = useRuntimeConfig().public
</script>
