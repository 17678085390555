export default {
  categories: {
    id: 'menu_categories',
    defaultMessage: 'Categories',
  },
  see_all: {
    id: 'menu_see_all',
    defaultMessage: 'See all',
  },
  goodDeals: {
    id: 'menu_good_deals',
    defaultMessage: 'Good deals',
  },
  linkTradeIn: {
    id: 'mweb_nav_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  shop: {
    id: 'mweb_nav_to_shop',
    defaultMessage: 'Shop',
  },
  linkAboutUs: {
    id: 'mweb_nav_to_about_us',
    defaultMessage: 'About us',
  },
  linkVerifiedRefurbished: {
    id: 'header_to_verified_refurbished',
    defaultMessage: 'Verified Refurbished',
  },
  linkBackStories: {
    id: 'header_to_back_stories',
    defaultMessage: 'Back Stories',
  },
  linkHelp: {
    id: 'mweb_nav_to_help',
    defaultMessage: 'Help',
  },
  aboutUs: {
    id: 'header_to_about_us',
    defaultMessage: 'About us',
  },
  sellYourTech: {
    id: 'header_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  help: {
    id: 'header_to_help',
    defaultMessage: 'Help',
  },
  verifiedRefurbished: {
    id: 'header_to_verified_refurbished',
    defaultMessage: 'Verified Refurbished',
  },
  backStories: {
    id: 'header_to_back_stories',
    defaultMessage: 'Back Stories',
  },
  goodToKnow: {
    id: 'good_to_know',
    defaultMessage: 'Good to know',
  },
}
