<template>
  <div
    class="bg-surface-default-mid border-static-default-low flex border-b pl-24"
  >
    <BurgerMenuIcon
      class="focus-visible-outline-inset-hi mr-12 hidden p-8 lg:flex"
      data-test="desktop-burger-menu"
      @burger-menu-clicked="emit('burger-menu-clicked')"
    />
    <BurgerMenu v-if="burgerMenuState === 'open'" />

    <TopSalesBar class="hidden lg:flex" />
  </div>
</template>

<script setup lang="ts">
import { useNavigation } from '../composables/useNavigation'

import BurgerMenu from './BurgerMenu.vue'
import BurgerMenuIcon from './BurgerMenuIcon.vue'
import TopSalesBar from './TopSalesBar.vue'

const { state: burgerMenuState } = useNavigation()
const emit = defineEmits(['burger-menu-clicked'])
</script>
