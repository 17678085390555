<template>
  <nav
    aria-label="menu"
    class="bg-surface-default-low fixed inset-0 z-20 flex flex-col"
  >
    <Header />
    <MenuSection class="overflow-auto" />
    <Footer />
    <div
      v-if="FF_ENABLE_COUNTRY_SWITCH"
      class="border-static-default-low border-t px-20 py-12"
    >
      <CountrySwitchMenuFullScreen />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'

import CountrySwitchMenuFullScreen from '../CountrySwitch/CountrySwitchMenuFullScreen.vue'

import Footer from './Footer.vue'
import Header from './Header.vue'
import MenuSection from './MenuSection.vue'

const { FF_ENABLE_COUNTRY_SWITCH } = useRuntimeConfig().public
</script>
