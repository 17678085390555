import { useRuntimeConfig } from '#imports'
import { type RouteLocationRaw } from 'vue-router'

import type { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { tw } from '@backmarket/utils/string/tw'
import { IconSwap } from '@ds/icons/IconSwap'

import {
  ABOUT_US,
  ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
} from '~/scopes/about-us/routes.constants'
import { CMS } from '~/scopes/cms/routes-names'
import { HELP_CENTER } from '~/scopes/help-center/routes'

import translations from '../components/NavMenu/NavMenu.translations'

export function useNavLinks() {
  const i18n = useI18n()
  const locale = useI18nLocale()
  const {
    market: { countryCode },

    features,
  }: {
    features: {
      buyback: { enabled: boolean }
    }
    market: { countryCode: MarketCountryCode }
  } = useMarketplace()
  const config = useRuntimeConfig()

  const linksConfig = [
    {
      condition: () => features?.buyback?.enabled,
      name: 'footer_buyback',
      href: {
        name: CMS.BUYBACK,
        params: { locale, pageName: 'home' },
      },
      getLabel: () => i18n(translations.sellYourTech),
      class: tw`text-static-brand-mid`,
      icon: IconSwap,
      url: `/${locale}/buyback/home`,
    },
    {
      condition: () =>
        config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_HEADER?.includes(
          countryCode,
        ),
      name: 'footer_verified_refurbished',
      href: {
        name: ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
        params: { locale },
      },
      getLabel: () => i18n(translations.linkVerifiedRefurbished),
      url: `/${locale}/quality`,
    },
    {
      condition: () =>
        !config.public.FF_ENABLE_VERIFIED_REFURBISHED_LINK_HEADER?.includes(
          countryCode,
        ),
      name: 'footer_about_us',
      href: { name: ABOUT_US, params: { locale } },
      url: `/${locale}/about-us`,
      getLabel: () => i18n(translations.linkAboutUs),
    },
    {
      condition: () =>
        config.public.FF_ENABLE_BACK_STORIES_LINK_HEADER?.includes(countryCode),
      name: 'footer_back_stories',
      href: { name: CMS.BACK_STORIES, params: { locale, pageName: 'home' } },
      getLabel: () => i18n(translations.linkBackStories),
      url: `/${locale}/back-stories/home`,
    },
    {
      condition: () => true,
      name: 'footer_help',
      href: { name: HELP_CENTER.HOME, params: { locale } },
      getLabel: () => i18n(translations.linkHelp),
      url: `/${locale}/help`,
    },
  ]

  const links: Array<{
    name: string
    href: RouteLocationRaw
    label: string
    url?: string
    icon?: object
    class?: string
  }> = linksConfig
    .filter((item) => item.condition())
    .map(({ condition, getLabel, ...item }) => ({ ...item, label: getLabel() }))

  return { links }
}
