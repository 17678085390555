import { ROUTES as AUTH_ROUTE_NAMES } from '../auth/route-names'
import { ROUTE_NAMES as BUY_BACK_ROUTE_NAMES } from '../buyback/constants'
import { CHECKOUT as CHECKOUT_ROUTE_NAMES } from '../checkout/routes-names'
import { PRODUCT } from '../product/route-names'

export type TrackingName = 'accept' | 'refuse' | 'close'

export const TRACKING_ZONE = 'prompt_notification'
export const SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS = 600
export const LOCALSTORAGE_KEY = 'declined_braze_soft_prompt_at'

const HALF_A_YEAR_IN_MS = 1_000 * 3_600 * 24 * 180
const SIXTY_SECONDS_IN_MS = 1_000 * 60

export const TIME_IN_MS = {
  TO_WAIT_BETWEEN_PROMPTS: HALF_A_YEAR_IN_MS,
  BEFORE_SHOWING_SOFT_PROMPT: SIXTY_SECONDS_IN_MS,
}

export const DO_NOT_DISTURB_ROUTE_NAMES = [
  BUY_BACK_ROUTE_NAMES,
  CHECKOUT_ROUTE_NAMES,
  AUTH_ROUTE_NAMES,
  PRODUCT.HOME,
]
