<template>
  <footer
    id="main-footer"
    class="bg-float-default-low text-static-default-hi p-32"
  >
    <div class="mx-auto lg:w-[1120px]">
      <ul class="flex list-none flex-col gap-32 md:flex-row">
        <li
          v-for="(footerCategory, index) in footerCategories"
          :key="index"
          class="md:basis-full"
        >
          <p v-if="footerCategory.title" class="heading-3 mb-16" tabindex="0">
            {{ i18n(footerCategory.title) }}
          </p>
          <FooterCategory :content="footerCategory.content" />
        </li>
      </ul>
      <RevDivider class="my-20" />
      <div
        class="flex flex-col items-center justify-between gap-12 pt-20 lg:flex-row"
      >
        <FooterFlags />
        <FooterCompanyDisclaimer />
        <AppStoreButtons campaign="footer" />
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'

import FooterCategory from './FooterCategory.vue'
import FooterCompanyDisclaimer from './FooterCompanyDisclaimer.vue'
import FooterFlags from './FooterFlags.vue'
import { useFooterCategories } from './useFooterCategories/useFooterCategories'

const i18n = useI18n()

const footerCategories = useFooterCategories()
</script>
