<template>
  <RevLink
    :aria-expanded="burgerMenuState === 'open'"
    :aria-label="i18n(translations.allOurProductsNew)"
    class="flex items-center gap-8"
    :underlined="false"
    @click="toggleMenu"
  >
    <div class="relative h-16 w-20">
      <span
        class="bg-action-default-hi absolute block h-1 w-full duration-200"
        :class="{
          'top-0': burgerMenuState === 'closed',
          'top-1/2 rotate-45 opacity-100': burgerMenuState === 'open',
        }"
      />
      <span
        class="bg-action-default-hi absolute top-1/2 block h-1 duration-200"
        :class="{
          'w-full': burgerMenuState === 'closed',
          'top-1/2 w-0 opacity-0': burgerMenuState === 'open',
        }"
      />
      <span
        class="bg-action-default-hi absolute block h-1 w-full duration-200"
        :class="{
          'top-full': burgerMenuState === 'closed',
          'top-1/2 -rotate-45 opacity-100': burgerMenuState === 'open',
        }"
      />
    </div>
    <span class="body-2-bold hidden self-center lg:block">
      {{ i18n(translations.allOurProductsNew) }}
    </span>
  </RevLink>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import { useNavigation } from '~/scopes/navigation/composables/useNavigation'

import translations from './Header.translations'

const { trackClick } = useTracking()

const i18n = useI18n()
const { state: burgerMenuState, toggle } = useNavigation()
function toggleMenu() {
  toggle()
  trackClick({
    zone: 'nav',
    name: 'all_product',
  })
}
</script>
