<template>
  <Transition
    appear
    enter-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-x-0"
    enter-from-class="!opacity-0 translate-x-[100%]"
    leave-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-x-0"
    leave-to-class="!opacity-0 translate-x-[100%]"
  >
    <div
      v-if="canDisplayPrompt"
      class="fixed top-[120px] z-20 mx-12 md:right-12 md:mx-0"
      data-test="soft-prompt"
    >
      <RevCard
        class="!shadow-long flex w-full flex-col items-center bg-[#F1EEE9] px-24 py-32 md:w-[458px]"
      >
        <RevButtonRounded
          :aria-label="i18n(translations.close)"
          class="absolute right-12 top-12"
          :icon="IconCross"
          variant="secondary"
          @click="onPromptClosed"
        />

        <div class="my-16 text-center">
          <FormattedMessage
            class="text-static-default-hi punchline mb-4 mt-16 md:heading-1"
            :definition="translations.title"
            tag="h3"
          >
            <template #italicText>
              <span class="punchline-italic md:md:heading-1-italic">
                {{ i18n(translations.titleItalicText) }}
              </span>
            </template>
          </FormattedMessage>

          <span class="text-static-default-mid body-1 md:body-2">
            {{ i18n(translations.message) }}
          </span>
        </div>

        <div class="relative hidden h-[281px] w-[258px] md:block">
          <picture>
            <RevIllustrationSource
              :height="281"
              :media="BreakpointQueries[Breakpoint.XS]"
              src="/img/prompt/gradient-phone.webp"
              type="image/webp"
              :width="258"
            />
            <RevIllustration
              alt=""
              class="absolute"
              :height="281"
              src="/img/prompt/gradient-phone.png"
              :width="258"
            />
          </picture>
          <RevButtonBase
            class="absolute right-1/2 top-80 h-[162px] w-[162px] translate-x-1/2"
            :to="qrCode"
          >
            <picture>
              <RevIllustrationSource
                :height="162"
                :media="BreakpointQueries[Breakpoint.XS]"
                :src="`/img/prompt/qr-code.webp`"
                type="image/webp"
                :width="162"
              />
              <RevIllustration
                :alt="i18n(translations.qrCode)"
                :height="162"
                loading="eager"
                :src="`/img/prompt/qr-code.png`"
                :width="162"
              />
            </picture>
          </RevButtonBase>
        </div>

        <RevButton class="my-28" :to="link" variant="primary">
          {{ i18n(translations.cta) }}
        </RevButton>
      </RevCard>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  Breakpoint,
  BreakpointQueries,
} from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevIllustrationSource } from '@ds/components/IllustrationSource'
import { IconCross } from '@ds/icons/IconCross'

import translations from './SoftPrompt.translations'
import { usePrompt } from './usePrompt'

const i18n = useI18n()

const { canDisplayPrompt, onPromptClosed, link, qrCode } = usePrompt()
</script>
