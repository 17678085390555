<template>
  <RevLink
    class="flex h-full flex-nowrap items-center gap-12"
    :style="{ color }"
    :to="url"
    :underlined="false"
    @click="toggle"
  >
    <component :is="icon" v-if="icon" />
    <span>{{ title }}</span>
  </RevLink>
</template>

<script setup lang="ts">
import { type Component } from 'vue'

import { RevLink } from '@ds/components/Link'

import { useNavigation } from '../../composables/useNavigation'

defineProps<{
  title: string
  color: string
  icon?: Component
  url: string
}>()
const { toggle } = useNavigation()
</script>
