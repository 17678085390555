<template>
  <RevListItemInteractive
    class="text-action-default-hi items-center capitalize"
    :to="link"
  >
    <template #prefix>
      <RevCountryFlag
        class="rounded-xs"
        :country-code="countryCode"
        size="small"
      />
    </template>
    <template #label>
      {{ countryName }}
    </template>
    <template #suffix>
      {{ displayName }}
    </template>
  </RevListItemInteractive>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  type CountryCodeValue,
  type Locale,
  MarketCountryCode,
} from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCountryFlag } from '@ds/components/CountryFlag'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'

import { useLinkResolver } from '~/composables/useLinkResolver'
import { HOME } from '~/scopes/home/route-names'

import { countryDisplayName } from '../../utils/countryDisplayName'

const props = defineProps<{
  countryCode: CountryCodeValue
  defaultLocale: Locale
}>()

const i18n = useI18n()
const { getLink } = useLinkResolver()
const countryName = computed(() => i18n.country(props.countryCode))
const displayName = countryDisplayName(
  props.defaultLocale,
  MarketCountryCode[props.countryCode],
)
const link = computed(
  () =>
    getLink({
      link: { name: HOME },
      countryCode: MarketCountryCode[props.countryCode],
    })?.href ?? '#',
)
</script>
