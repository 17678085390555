/**
 * Creates an array composed of all the values
 * of an object regardless its depth
 *
 * @param {Object} obj - object of any depth
 * @return {*[]} - array of values from obj values
 *
 * @example
 *  objectValuesDeep({
 *    level1: {
 *      level2: 'hello',
 *      level2b: {
 *        level3: null,
 *        level3b: {
 *          level4: undefined,
 *          level4b: [1, 2, 3],
 *          leval4c: {
 *            level5: true,
 *          },
 *        },
 *      },
 *    },
 *    level1b: '123',
 *  });
 *
 *  // output: ['hello', undefined, 1, 2, 3, true, '123']
 */

export function objectValuesDeep(obj: Record<string, unknown>): unknown[] {
  if (!obj) {
    return []
  }

  const collections = Object.values(obj).reduce(
    (acc: unknown[], current: unknown) => {
      const currentValue =
        typeof current !== 'object'
          ? current
          : objectValuesDeep(current as Record<string, unknown>)

      return [...acc, currentValue]
    },
    [],
  )

  return collections.flat()
}
