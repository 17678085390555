<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    class="group flex h-full items-center transition-all duration-700 ease-in-out"
    role="none"
  >
    <RevLink
      :aria-expanded="String(activeMenu === id)"
      :aria-haspopup="String(hasPopup)"
      class="bold-hack block h-full transition-all duration-700 ease-in-out hover:body-2-bold hover:no-underline"
      :class="[
        isActivePage(url) && !isMenuActive ? 'body-2-bold' : 'body-2',
        isMenuActive ? 'text-action-default-low' : 'text-action-default-hi',
      ]"
      role="menuitem"
      :style="{ color }"
      :title="title"
      :to="url"
      :tracking="{ zone: 'header', name: 'department', value: title }"
      :underlined="false"
    >
      <div
        class="flex h-full items-center border-t-transparent"
        :class="[
          hasPopup
            ? 'group-hover:border-b-static-default-hi group-hover:border-y-2'
            : '',
          isActivePage(url) && !isMenuActive
            ? 'border-b-static-default-hi border-y-2'
            : '',
        ]"
      >
        <span>{{ title }}</span>
      </div>
    </RevLink>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { RevLink } from '@ds/components/Link'

import { useNavigation } from '../../composables/useNavigation'

defineProps<{
  url: string
  id?: number
  color?: string
  title: string
  hasPopup?: boolean
}>()

const { isMenuActive, activeMenu, isActivePage } = useNavigation()
</script>

<style scoped>
.bold-hack::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: content;
  border: 1px solid black;
}
</style>
