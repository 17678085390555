<template>
  <div
    class="flex-1 items-center overflow-hidden whitespace-nowrap"
    data-test="topsalesbar"
  >
    <div class="flex items-center overflow-x-scroll py-8 scrollbar-none">
      <span
        v-for="(sale, index) in topSales"
        :key="sale.title_display"
        class="body-2 mr-8 first:pl-8 last:mr-48"
      >
        <RevLink
          class="focus-visible-outline-inset-hi !font-weight-body-1 p-8"
          :style="sale.style"
          :to="sale.link"
          :underlined="false"
          @click="trackHorizontalNav(sale, index)"
        >
          {{ sale.title }}
        </RevLink>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { TopSale } from '@backmarket/http-api/src/api-specs-navigation-experience/navigation/topsales'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLink } from '@ds/components/Link'

import { useTopSales } from '../composables/useTopSales'

const { trackClick } = useTracking()

const { data } = useTopSales()

const topSales = computed(() => {
  if (
    data.value === null ||
    data.value === undefined ||
    !Array.isArray(data.value)
  )
    return []

  return data.value
    .filter((sale) => sale.title && !isEmpty(sale.link))
    .map((sale) => ({
      ...sale,
      style: {
        ...sale.style,
        color: sale.style.text_color,
      },
    }))
})

function trackHorizontalNav(sale: TopSale, index: number) {
  trackClick({
    zone: 'header',
    name: `topsales-${index}-${sale.title}`,
  })
}
</script>
