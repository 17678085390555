export default {
  title: {
    id: 'prompt_title',
    defaultMessage: 'It’s all in the {italicText, html}.',
  },
  titleItalicText: {
    id: 'prompt_title_italic_text',
    defaultMessage: 'wrist',
  },
  message: {
    id: 'prompt_text',
    defaultMessage:
      'Shake your phone to find how much it’s worth and get and extra $30 when you Trade it in.',
  },
  close: {
    id: 'prompt_close',
    defaultMessage: 'Close',
  },
  qrCode: {
    id: 'prompt_qr_code',
    defaultMessage: 'Scan the QR code or visit the link to download the app',
  },
  cta: {
    id: 'prompt_cta',
    defaultMessage: 'Download the App',
  },
}
