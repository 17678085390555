<template>
  <Transition
    appear
    enter-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-x-0"
    enter-from-class="!opacity-0 translate-x-[100%]"
    leave-active-class="motion-safe:transition-all opacity-100 !duration-300 ease-in-out translate-x-0"
    leave-to-class="!opacity-0 translate-x-[100%]"
  >
    <div
      v-if="isVisible"
      class="fixed right-12 top-[120px] z-20 hidden md:block"
      data-test="braze-prompt"
    >
      <div class="w-full max-w-[360px]">
        <RevCard
          class="!shadow-long mood-tangaroa flex flex-col items-center p-24"
        >
          <RevButtonRounded
            class="absolute right-12 top-12"
            data-test="close"
            :icon="IconCross"
            variant="secondary"
            @click="declineFromClick('close')"
          />

          <div class="mb-16 mt-24 text-center">
            <h3 class="text-static-default-hi heading-1 mb-8">
              {{ i18n(translations.title) }}
            </h3>
            <span class="text-static-default-mid body-2">
              {{ i18n(translations.message) }}
            </span>
          </div>

          <div>
            <RevButtonTiny
              class="mr-4"
              data-test="ok"
              variant="primary"
              @click="handleClick"
            >
              {{ i18n(translations.okButtonLabel) }}
            </RevButtonTiny>

            <RevButtonTiny
              class="ml-4"
              data-test="decline"
              variant="secondary"
              @click="declineFromClick('refuse')"
            >
              {{ i18n(translations.cancelButtonLabel) }}
            </RevButtonTiny>
          </div>
        </RevCard>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { getBrazeInstance } from '@backmarket/nuxt-module-tracking/getBrazeInstance'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'
import { IconCross } from '@ds/icons/IconCross'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '../../constants/trackingPageType'

import {
  LOCALSTORAGE_KEY,
  SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS,
  TIME_IN_MS,
  TRACKING_ZONE,
  type TrackingName,
} from './BrazePrompt.constants'
import translations from './BrazePrompt.translations'
import { useCanDisplayPrompt } from './composables/useCanDisplayPrompt'

const i18n = useI18n()
const tracking = useTracking()
const braze = getBrazeInstance()
const route = useRoute()
const localStorage = useLocalStorage()
const canDisplayPrompt = useCanDisplayPrompt()

const isVisible = ref(false)
const scrollReferencePoint = ref(0)

const pageType = computed(() => {
  const routeName = route.name?.toString() || ''

  return TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName]
})

function hide() {
  isVisible.value = false
}

function decline() {
  const nowInMs = Date.now()
  localStorage.setItem(LOCALSTORAGE_KEY, nowInMs.toString())

  hide()
}

function declineFromClick(name: TrackingName) {
  tracking.trackClick({
    name,
    zone: TRACKING_ZONE,
    pageType: pageType.value,
  })

  decline()
}

function requestPushPermission() {
  if (!braze) {
    return
  }

  braze.requestPushPermission()

  hide()
}

function handleClick() {
  requestPushPermission()

  tracking.trackClick({
    name: 'accept',
    zone: TRACKING_ZONE,
    pageType: pageType.value,
  })
}

function dismissAfterScroll() {
  const actualOffset = Math.abs(window.scrollY - scrollReferencePoint.value)

  const shouldBeDismissed =
    actualOffset >= SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS

  if (shouldBeDismissed) {
    decline()
  }
}

function watchScrolling() {
  if (isVisible.value) {
    scrollReferencePoint.value = window.scrollY
    window.addEventListener('scroll', dismissAfterScroll)
  } else {
    window.removeEventListener('scroll', dismissAfterScroll)
  }
}

function showAfterDelay() {
  if (!braze) {
    return
  }

  setTimeout(() => {
    isVisible.value = canDisplayPrompt()
  }, TIME_IN_MS.BEFORE_SHOWING_SOFT_PROMPT)
}

function trackImpression() {
  if (isVisible.value) {
    tracking.trackPromptNotificationImpression({
      zone: TRACKING_ZONE,
      pageType: pageType.value,
      routeName: route.name?.toString() || '',
    })
  }
}

watch(isVisible, () => {
  watchScrolling()
  trackImpression()
})

onMounted(() => {
  showAfterDelay()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', dismissAfterScroll)
})
</script>
