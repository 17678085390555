export default {
  textboxPlaceholder: {
    id: 'searchbar_placeholder',
    defaultMessage: 'Looking for something (refurbished) in particular?',
  },
  resetSearchButtonAriaLabel: {
    id: 'searchbar_reset_searchbar_button_label',
    defaultMessage: 'Reset search bar',
  },
  searchButtonAriaLabel: {
    id: 'searchbar_search_button_label',
    defaultMessage: 'Search',
  },
}
