import { useRoute } from '#imports'

import {
  type BrazeInstance,
  getBrazeInstance,
} from '@backmarket/nuxt-module-tracking/getBrazeInstance'
import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'

import { isIncludedInRoutes } from '~/utils/navigation/isIncludedInRoutes'

import {
  DO_NOT_DISTURB_ROUTE_NAMES,
  LOCALSTORAGE_KEY,
  TIME_IN_MS,
} from '../BrazePrompt.constants'

function isAllowedForCurrentBrowser(braze: BrazeInstance) {
  const isPushSupported = braze.isPushSupported()
  const isAlreadyGranted = braze.isPushPermissionGranted()
  const isAlreadyBlocked = braze.isPushBlocked()

  // `isPushSupported` can be `undefined`. Forcing it to a boolean
  // allows the expected behavior even when it's the case.
  return Boolean(isPushSupported) && !isAlreadyGranted && !isAlreadyBlocked
}

function hasTimeElapsed({
  nowInMs,
  lastDeclinedTimeInMs,
}: {
  nowInMs: number
  lastDeclinedTimeInMs: number
}) {
  const timeElapsedInMs = nowInMs - lastDeclinedTimeInMs

  return timeElapsedInMs > TIME_IN_MS.TO_WAIT_BETWEEN_PROMPTS
}

function canBePromptedAgain() {
  const nowInMs = Date.now()
  const localStorage = useLocalStorage()
  const lastDeclinedTime = localStorage.getItem(LOCALSTORAGE_KEY) || '0'
  const lastDeclinedTimeInMs = parseInt(lastDeclinedTime, 10)

  return hasTimeElapsed({ nowInMs, lastDeclinedTimeInMs })
}

export function canDisplayPrompt(): boolean {
  const route = useRoute()
  const braze = getBrazeInstance()

  if (!braze) {
    return false
  }

  const isAllowed = isAllowedForCurrentBrowser(braze)

  const canBePrompted = canBePromptedAgain()

  const canBeDisturbed = !isIncludedInRoutes(
    route.name?.toString(),
    DO_NOT_DISTURB_ROUTE_NAMES,
  )

  return isAllowed && canBePrompted && canBeDisturbed
}

export function useCanDisplayPrompt() {
  return canDisplayPrompt
}
