<template>
  <span class="sr-only" tabindex="0">
    {{ i18n(translations.flagsScreenReaderIntro) }}
  </span>
  <ul class="flex list-none flex-wrap justify-center gap-8">
    <li
      v-for="{ countryCode, href, isCurrentCountryPage } in footerFlagsLinks"
      :key="countryCode"
    >
      <RevButtonBase
        :aria-current="isCurrentCountryPage"
        :aria-label="
          i18n(translations.flagAlternativeText, {
            country: i18n.country(countryCode),
          })
        "
        class="opacity-60 transition duration-200 hover:opacity-100"
        :data-qa="`flag-${countryCode}`"
        :to="href"
      >
        <Flag :country-code="countryCode" data-test="flag" size="xl" />
      </RevButtonBase>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { Country, MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButtonBase } from '@ds/components/ButtonBase'

import { useLinkResolver } from '~/composables/useLinkResolver'
import { HOME } from '~/scopes/home/route-names'

import translations from './Footer.translations'

const i18n = useI18n()

const { market } = useMarketplace()
const { getLinks } = useLinkResolver()

const countryCodes = [
  MarketCountryCode.FR,
  MarketCountryCode.AT,
  MarketCountryCode.BE,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.FI,
  MarketCountryCode.GB,
  MarketCountryCode.GR,
  MarketCountryCode.IE,
  MarketCountryCode.IT,
  MarketCountryCode.NL,
  MarketCountryCode.PT,
  MarketCountryCode.SE,
  MarketCountryCode.SK,
  MarketCountryCode.US,
  MarketCountryCode.JP,
  MarketCountryCode.AU,
]

const marketCountriesFlagsLinks = getLinks({
  link: { name: HOME },
  countryCodes,
}).map(({ countryCode, ...rest }) => ({
  ...rest,
  countryCode,
  isCurrentCountryPage: market.countryCode === countryCode,
}))

const footerFlagsLinks: {
  countryCode: MarketCountryCode | Country
  href: string
  isCurrentCountryPage: boolean
}[] = [
  ...marketCountriesFlagsLinks,
  {
    countryCode: Country.KR,
    href: 'https://www.backmarket.co.kr',
    isCurrentCountryPage: false,
  },
]
</script>
