import { useRoute, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref, watch } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useLegalStore } from '@backmarket/nuxt-module-tracking/useLegalStore'
import { isBrowser } from '@backmarket/utils/env/isBrowser'

import { useNavigation } from '~/scopes/navigation/composables/useNavigation'

import { isIncludedInRoutes } from '../../../utils/navigation/isIncludedInRoutes'
import { DO_NOT_DISTURB_ROUTE_NAMES } from '../appsflyer.constants'

export function useAppsflyer() {
  const route = useRoute()
  const runtimeConfig = useRuntimeConfig()
  const legalStore = useLegalStore()
  const { state } = useNavigation()
  const marketPlace = useMarketplace()

  const isCookieModalOpen = computed(() => legalStore.isModalOpen)
  const isNavigationOpen = computed(() => state.value === 'open')

  const isBannerOpen = ref(false)

  function isInitialized() {
    return isBrowser() && 'AF' in window
  }

  function showBanner() {
    if (!isInitialized()) return

    try {
      window.AF?.('banners', 'showBanner')
      isBannerOpen.value = true
    } catch {
      // fail silently
    }
  }

  function hideBanner() {
    if (!isInitialized()) return

    try {
      window.AF?.('banners', 'hideBanner')
      isBannerOpen.value = false
    } catch {
      // fail silently
    }
  }

  function isForbiddenOnCurrentRoute() {
    const currentRoute = route.name?.toString() || ''

    return isIncludedInRoutes(currentRoute, DO_NOT_DISTURB_ROUTE_NAMES)
  }

  function isAllowedOnCurrentCountry() {
    const currentCountry = marketPlace.market.countryCode
    const enabledCountryCodes =
      runtimeConfig.public.APPSFLYER_ENABLED_COUNTRY_CODES || ''

    return enabledCountryCodes.includes(currentCountry)
  }

  function isAllowedOnCurrentRoute() {
    return !isForbiddenOnCurrentRoute()
  }

  function isForbiddenOnCurrentCountry() {
    return !isAllowedOnCurrentCountry()
  }

  function shouldShowBanner() {
    const isBannerAllowedOnCountry = isAllowedOnCurrentCountry()
    const isBannerAllowedOnRoute = isAllowedOnCurrentRoute()
    const isCookieBannerClosed = isCookieModalOpen.value === false
    const isNavigationClosed = isNavigationOpen.value === false
    const isBannerClosed = isBannerOpen.value === false

    return (
      isBannerAllowedOnCountry &&
      isBannerAllowedOnRoute &&
      isCookieBannerClosed &&
      isNavigationClosed &&
      isBannerClosed
    )
  }

  function showBannerIfAllowed() {
    if (shouldShowBanner()) {
      showBanner()
    }
  }

  function shouldHideBanner() {
    return (
      isForbiddenOnCurrentCountry() ||
      isForbiddenOnCurrentRoute() ||
      isNavigationOpen.value === true ||
      isCookieModalOpen.value === true
    )
  }

  function executeBannerAction() {
    const executeAction = shouldHideBanner() ? hideBanner : showBannerIfAllowed
    executeAction()
  }

  watch(() => route.name, executeBannerAction)
  watch(isNavigationOpen, executeBannerAction)
  watch(isCookieModalOpen, executeBannerAction)
  onMounted(executeBannerAction)
}
