<template>
  <RevIllustration
    :alt="logoAlt"
    class="h-[76px] w-[46px]"
    :height="76"
    src="/img/footer/customer-service-of-the-year.svg"
    :width="46"
  />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Footer.translations'

const i18n = useI18n()
const logoAlt = i18n(translations.customerServiceOfTheYearLogoAlt)
</script>
