export default {
  navigationBuybackNew: {
    id: 'navigation_buyback_new',
    defaultMessage: 'Sell your device',
  },
  navigationQualityStandardsNew: {
    id: 'navigation_quality_standards_new',
    defaultMessage: 'Our quality standards',
  },
  navigationCustomerRatingsNew: {
    id: 'navigation_customer_ratings_new',
    defaultMessage: 'Verified customers ratings',
  },
}
