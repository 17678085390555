import { flatMap } from '@backmarket/utils/collection/flatMap'
import { objectValuesDeep } from '@backmarket/utils/object/objectValuesDeep'

type RouteObject = Record<string, unknown>
type RouteNamesCollection = (string | RouteObject)[]

/**
 * Returns `true` if the route name is included in the route names collection, and `false` otherwise
 *
 * @param {string} routeName - The name of the route to check against the route names collection.
 * @param {RouteNamesCollection} routeNames - The collection of route names that may include the route name.
 *
 * @return {boolean}
 *
 * @example
 * const routeNames = [
 *   'offer',
 *   {
 *     SHIPPING: 'shipping',
 *     SERVICES: {
 *       CARRIER: 'carrier',
 *     },
 *   },
 * ]
 *
 * isIncludedInRoutes('carrier', routeNames) // returns `true`
 * isIncludedInRoutes('home', routeNames) // returns `false`
 */
export function isIncludedInRoutes(
  routeName = '',
  routeNames = <RouteNamesCollection>[],
) {
  const flattenRoutes = flatMap(routeNames, (route) => {
    const isParentRoute = typeof route === 'string'

    return isParentRoute ? route : objectValuesDeep(route as RouteObject)
  })

  return flattenRoutes.includes(routeName)
}
