<template>
  <section>
    <MenuTitle
      class="fixed inset-x-0 top-0 h-52"
      :text-color="current.textColor"
      :title="current.title"
      @close="close"
      @previous="back"
    />
    <Transition
      appear
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="opacity-0 border-opacity-0"
    >
      <div class="bg-static-default-low fixed inset-x-0 top-52 h-[54px]"></div>
    </Transition>
    <Transition
      appear
      enter-active-class="transition-all duration-300 ease-in-out"
      enter-from-class="h-0 translate-y-[54px]"
      enter-to-class="h-full translate-y-0"
    >
      <div
        class="bg-static-default-low border-static-default-low fixed inset-x-0 bottom-0 top-52 border-t"
        :class="{ 'pb-48': FF_ENABLE_COUNTRY_SWITCH }"
      >
        <nav class="flex h-full flex-col justify-start overflow-scroll">
          <FirstAnimationMenuItems
            v-if="firstState && state === 'open'"
            :links="mainLinks"
            :menu-items="current.items"
            @link-click="onLinkClick"
            @menu-item-click="onMenuItemClick"
          />
          <MenuItems
            v-else-if="breadcrumb.length === 1"
            :current="current.currentItem"
            :links="mainLinks"
            :menu-items="current.items"
            :with-extra-menu="true"
            @link-click="onLinkClick"
            @menu-item-click="onMenuItemClick"
          />
          <MenuItems
            v-else
            :key="current.title"
            :current="current.currentItem"
            :menu-items="current.items"
            :with-extra-menu="false"
            @menu-item-click="onMenuItemClick"
          />
          <RevStickyBar v-if="FF_ENABLE_COUNTRY_SWITCH">
            <CountrySwitchMenuFullScreen />
          </RevStickyBar>
        </nav>
      </div>
    </Transition>
  </section>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed, ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevStickyBar } from '@ds/components/StickyBar'

import { useNavLinks } from '../composables/useNavLinks'
import { type Item, useNavigation } from '../composables/useNavigation'

import CountrySwitchMenuFullScreen from './CountrySwitch/CountrySwitchMenuFullScreen.vue'
import FirstAnimationMenuItems from './FirstAnimationMenuItems.vue'
import MenuItems from './MenuItems.vue'
import MenuTitle from './MenuTitle.vue'

const { FF_ENABLE_COUNTRY_SWITCH } = useRuntimeConfig().public

const { menu, state, toggle: toggleBurgerMenu } = useNavigation()
const breadcrumb = ref<
  {
    title?: string
    textColor?: string
    items: Item[]
    currentItem?: Item
  }[]
>([{ items: menu.value ?? [] }])
const current = computed(() => {
  return breadcrumb.value[breadcrumb.value.length - 1]
})
const firstState = ref(true)

const { links: mainLinks } = useNavLinks()

function back() {
  breadcrumb.value.pop()
}
function forward(item: Item) {
  firstState.value = false
  breadcrumb.value.push({
    items: item.children,
    title: item.title,
    textColor: item.color,
    currentItem: breadcrumb.value.length === 1 ? item : undefined,
  })
}

const { trackClick } = useTracking()
function onMenuItemClick({ item }: { item: Item }) {
  if (item.children?.length > 0) {
    forward(item)
  } else {
    trackClick({
      zone: 'nav',
      name: breadcrumb.value
        .map(({ title }) => title)
        .concat(item.title)
        .join('_'),
    })
    toggleBurgerMenu()
  }
}

function onLinkClick(link: { name: string }) {
  trackClick({ zone: 'nav', name: link.name })
  toggleBurgerMenu()
}

function close() {
  toggleBurgerMenu()
  breadcrumb.value = [{ items: menu.value }]
}
</script>
