export default {
  title: {
    id: 'braze_prompt_title',
    defaultMessage: 'Want to know our secrets?',
  },
  message: {
    id: 'braze_prompt_text',
    defaultMessage:
      'Like our latest and greatest deals? If yes, allow notifications in your browser.',
  },
  close: {
    id: 'braze_prompt_close',
    defaultMessage: 'Close',
  },
  okButtonLabel: {
    id: 'braze_prompt_ok_button_label',
    defaultMessage: 'Yes, please',
  },
  cancelButtonLabel: {
    id: 'braze_prompt_cancel_button_label',
    defaultMessage: 'Maybe later',
  },
}
