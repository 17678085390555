export default {
  popularSearches: {
    id: 'searchbar_popular_searches_title',
    defaultMessage: 'Popular searches',
  },
  suggestedSearches: {
    id: 'searchbar_suggested_searches_title',
    defaultMessage: 'Suggested searches',
  },
  resultsListing: {
    id: 'searchbar_results_listing_count',
    defaultMessage: '{resultsCount} results for "{searchQuery}"',
  },
}
