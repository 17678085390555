export default {
  back: {
    id: 'mweb_nav_back',
    defaultMessage: 'Back',
  },
  search: {
    id: 'mweb_nav_search',
    defaultMessage: 'Search',
  },
}
