<template>
  <div>
    <AdminMenu v-if="isAdmin" data-test="icon-admin" />
    <UserIconAndSubMenu v-else />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'

import AdminMenu from './AdminMenu.vue'
import UserIconAndSubMenu from './UserIconAndSubMenu.vue'

const userStore = useUserStore()
const isAdmin =
  userStore.user.isStaff ||
  userStore.user.isShadowingClient ||
  userStore.user.isShadowingMerchant
</script>
