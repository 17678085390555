<template>
  <div class="bg-surface-default-low body-2-bold">
    <template v-for="link in links" :key="link.title">
      <hr class="border-static-default-low" />
      <RevLink
        class="focus-visible-outline-inset-hi flex h-72 items-center pl-20 md:h-56"
        :data-test="link.name"
        :to="link.href"
        :underlined="false"
        @click.self="toggle"
      >
        <component :is="link.icon" class="mr-12" />

        {{ link.label }}
      </RevLink>
    </template>
  </div>
</template>

<script setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconStarOutlined } from '@ds/icons/IconStarOutlined'

import { ABOUT_US } from '~/scopes/about-us/routes.constants'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import { useNavigation } from '../composables/useNavigation'

import translations from './MenuFooter.translations'

const { toggle } = useNavigation()

const i18n = useI18n()
const { currentLocale: locale } = useI18n()

const customerRatingsExceptions = {
  'fr-be':
    'https://www.trustedshops.fr/evaluation/info_XB9BE628A37B8B2E1477584BD94039251.html',
  'fr-fr':
    'https://www.trustedshops.fr/evaluation/info_X194F6470570B4368A91A3C7230E9014C.html',
  'de-de':
    'https://www.trustedshops.de/bewertung/info_X0D2D87BCBFAE89812C9338C968A4BD77.html',
  'it-it':
    'https://www.trustedshops.it/valutazione-del-negozio/info_X96B2C7A20819DB8EA5BC418A0A9AA324.html',
  'es-es':
    'https://www.trustedshops.es/evaluacion/info_X416F4F5FD9E8CB7EC326CBA57589BE5B.html',
}

const links = [
  {
    class: 'flex',
    name: 'footer_about_us',
    href: { name: ABOUT_US, params: { locale } },
    label: i18n(translations.navigationQualityStandardsNew),
    icon: IconQuality,
  },
  {
    class: 'flex',
    name: 'footer_customer_ratings',
    href: customerRatingsExceptions[locale] || {
      name: REVIEWS_ROUTES.PAGES.ALL,
      params: { locale },
    },
    label: i18n(translations.navigationCustomerRatingsNew),
    icon: IconStarOutlined,
    target: '_blank',
  },
]
</script>
