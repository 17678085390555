<template>
  <MenuFullscreen class="lg:hidden" v-bind="$attrs" />
  <MenuSidebar class="hidden lg:block" v-bind="$attrs" />
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'

import MenuFullscreen from './MenuFullscreen.vue'
import MenuSidebar from './MenuSidebar.vue'

defineOptions({
  inheritAttrs: false,
})

onBeforeUnmount(() => {
  window.document.body.style.overflow = ''
  window.document.body.style.height = ''
})

onMounted(() => {
  window.document.body.style.overflow = 'hidden'
  window.document.body.style.height = '100%'
})
</script>
