import { type Router } from 'vue-router'

import type { Link } from '@backmarket/http-api'
import { type Logger } from '@backmarket/nuxt-module-logger/types'

function isError(error: unknown): error is Error {
  return error instanceof Error
}

export function linkToUrl(
  link: Link | string | undefined,
  router: Router,
  logger: Logger,
): string {
  if (typeof link === 'string') return link
  if (!link) return '#'
  if (link.type === 'external') return link.href

  try {
    const searchParamsForHash = new URLSearchParams(link.hash)
    const resolvedRoute = router.resolve({
      name: link.name,
      params: link.params,
      query: link.query,
      hash: searchParamsForHash.size > 0 ? `#${searchParamsForHash}` : '',
    })

    return resolvedRoute.href ?? '#'
  } catch (err) {
    logger.error('This URL cannot be resolved', {
      badLink: link,
      error: isError(err) ? err : new Error(`${err}`),
    })
  }

  return '#'
}
