<template>
  <div>
    <div class="flex gap-24">
      <RevLink
        v-for="link in links"
        :key="link.name"
        class="flex items-center gap-4"
        data-test="footer-link"
        :to="link.url"
        :tracking="{ name: link.name, zone: 'header' }"
        :underlined="false"
      >
        <component
          :is="link.icon"
          v-if="link.icon"
          class="text-static-brand-mid"
        />
        <span class="body-2-bold" :class="link.class">
          {{ link.label }}
        </span>
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../composables/useNavLinks'

const { links } = useNavLinks()
</script>
