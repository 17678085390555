import { useRoute } from '#imports'
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import type { Features } from '~/features'
import { isIncludedInRoutes } from '~/utils/navigation/isIncludedInRoutes'

import { ROUTES as AUTH_ROUTE_NAMES } from '../auth/route-names'
import { CHECKOUT as CHECKOUT_ROUTE_NAMES } from '../checkout/routes-names'
import { PRODUCT } from '../product/route-names'

/**
 * The prompt can be seen once per session.
 * It should not be displayed on a specific subset of routes
 * nor on countries where the app is not available
 */
export function usePrompt() {
  // 10 seconds in milliseconds
  const TIME_BEFORE_SHOWING_SOFT_PROMPT = 10 * 1_000
  const SESSION_STORAGE_KEY = 'soft_prompt_seen'
  const APP_DOWNLOAD_BASE_URL = 'https://app.backmarket.com/j4lA'
  const CAMPAIGN = 'WEB_AppDownloadPrompt'

  const route = useRoute()
  const {
    features,
    market: { countryCode },
  } = useMarketplace<Features>()

  const isVisible = ref(false)
  let timeout: NodeJS.Timeout

  function getCanDisplayPrompt() {
    const DO_NOT_DISTURB_ROUTE_NAMES = [
      CHECKOUT_ROUTE_NAMES,
      AUTH_ROUTE_NAMES,
      PRODUCT.HOME,
    ]

    const alreadySeen = window.sessionStorage
      ? window.sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true'
      : true

    const canBeDisturbed = !isIncludedInRoutes(
      route.name?.toString(),
      DO_NOT_DISTURB_ROUTE_NAMES,
    )

    const isAppAvailable = features.mobileApp.available

    return !alreadySeen && canBeDisturbed && isAppAvailable
  }

  function onPromptClosed() {
    isVisible.value = false
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
  }

  function buildLink({
    medium,
    content,
    xp,
    pid,
  }: {
    medium: string
    content: string
    xp: string
    pid: string
  }) {
    return `${APP_DOWNLOAD_BASE_URL}?af_web_dp=${window.location.href.split('?')[0]}&utm_source=web&utm_medium=${medium}&utm_campaign=ALL_${countryCode}_${CAMPAIGN}&utm_content=${content}&af_xp=${xp}&pid=${pid}&c=ALL_${countryCode}_${CAMPAIGN}&af_channel=backmarket&af_dp=backmarket://`
  }

  const qrCode = buildLink({
    medium: 'web',
    content: 'qr',
    xp: 'qr',
    pid: 'QR_code',
  })

  const link = buildLink({
    medium: 'PAGE',
    content: 'link',
    xp: 'Website',
    pid: 'Website',
  })

  onMounted(() => {
    timeout = setTimeout(() => {
      isVisible.value = getCanDisplayPrompt()
    }, TIME_BEFORE_SHOWING_SOFT_PROMPT)
  })

  onBeforeUnmount(() => {
    clearTimeout(timeout)
  })

  return {
    canDisplayPrompt: isVisible,
    onPromptClosed,
    qrCode,
    link,
  }
}
