import { onMounted, ref } from 'vue'

const SCROLL_SHIFT = 10
const SCROLL_THRESHOLD = 300

export function isHidden(prev: number, next: number, hidden: boolean) {
  if (next === 0) {
    return false
  }

  if (next > prev && next > SCROLL_THRESHOLD) {
    return true
  }

  return hidden && prev - next <= SCROLL_SHIFT
}

export function useHideOnScroll() {
  const hidden = ref(false)
  const scrollY = ref(0)

  function handleScroll() {
    hidden.value = isHidden(scrollY.value, window.scrollY, hidden.value)
    scrollY.value = window.scrollY
  }
  onMounted(() => {
    handleScroll()
    window.onscroll = handleScroll
  })

  return { hidden }
}
