<template>
  <RevContextualMenu list-class-names="transition-none" position="bottom-end">
    <template #trigger="{ show }">
      <CountrySwitchMenuButton
        :countryCode="thisMarket.countryCode"
        @click="show"
      />
    </template>

    <template #items="{ hide }">
      <div class="text-static-default-hi py-8">
        <div class="flex items-center px-24">
          <CountrySwitchMenuHeader @click="hide" />
        </div>
        <CountrySwitchMenuSearchList variant="compact" />
      </div>
    </template>
  </RevContextualMenu>
</template>

<script setup lang="ts">
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContextualMenu } from '@ds/components/ContextualMenu'

import CountrySwitchMenuButton from './CountrySwitchMenuButton.vue'
import CountrySwitchMenuHeader from './CountrySwitchMenuHeader.vue'
import CountrySwitchMenuSearchList from './CountrySwitchMenuSearchList.vue'

const thisMarket = useMarketplace().market
</script>
