<template>
  <div v-click-outside="closeSubMenu" class="flex md:relative">
    <RevLink
      v-if="!authenticated"
      :aria-label="i18n(translations.iconAltText)"
      data-qa="icon-avatar"
      :to="iconAvatarLink"
      :tracking="{
        zone: 'header',
        name: 'register',
      }"
      :underlined="false"
    >
      <div class="flex h-20 w-20 items-center justify-center md:h-40 md:w-40">
        <IconAvatar class="h-20 w-20 md:h-24 md:w-24" data-test="icon-avatar" />
      </div>
    </RevLink>

    <RevButtonBase
      v-if="authenticated"
      :aria-label="i18n(translations.iconAltText)"
      data-qa="icon-avatar-logged-in"
      :disabled="!hydrationCompleted"
      :tracking="{
        zone: 'header',
        name: 'dashboard',
      }"
      @click="toggleSubMenu"
    >
      <RevAvatar
        data-test="icon-login"
        :label="avatarLabel"
        :size="avatarSize"
        :thumbnail="authIconAvatar"
      />
    </RevButtonBase>

    <ul
      class="bg-overlap-default-low shadow-long rounded-sm absolute bottom-4 left-1/2 z-50 w-[calc(100vw-1.5rem)] -translate-x-1/2 translate-y-full list-none divide-y p-0 md:-bottom-16 md:left-auto md:right-0 md:w-256 md:translate-x-0"
      :class="{ block: isVisible, hidden: !isVisible }"
    >
      <li>
        <RevLink
          :to="resolveLocalizedRoute({ name: DASHBOARD.KYC.PROFILE })"
          :tracking="{
            zone: 'header',
            name: 'subnav_my_account',
          }"
          :underlined="false"
          @click="closeSubMenu"
        >
          <span class="body-2 flex w-full items-center px-16 py-20">
            <IconAvatar class="mr-20 h-20 w-20 md:h-24 md:w-24" />

            {{ i18n(translations.myAccount) }}
          </span>
        </RevLink>
      </li>
      <li class="border-static-default-low">
        <RevLink
          :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
          :tracking="{
            zone: 'header',
            name: 'subnav_my_orders',
          }"
          :underlined="false"
          @click="closeSubMenu"
        >
          <span class="body-2 flex w-full items-center px-16 py-20">
            <IconPackage class="mr-20 h-20 w-20 md:h-24 md:w-24" />

            {{ i18n(translations.myOrders) }}
          </span>
        </RevLink>
      </li>

      <li class="border-static-default-low">
        <RevLink
          :to="resolveLocalizedRoute({ name: DASHBOARD.FAVORITES })"
          :tracking="{
            zone: 'header',
            name: 'subnav_my_favorites',
          }"
          :underlined="false"
          @click="closeSubMenu"
        >
          <span class="body-2 flex w-full items-center px-16 py-20">
            <IconHeart class="mr-20 h-20 w-20 md:h-24 md:w-24" />
            {{ i18n(translations.myFavorites) }}
          </span>
        </RevLink>
      </li>

      <li v-if="showMySales" class="border-static-default-low">
        <RevLink
          :to="resolveLocalizedRoute({ name: DASHBOARD.MY_SALES })"
          :tracking="{
            zone: 'header',
            name: 'subnav_my_sales',
          }"
          :underlined="false"
          @click="closeSubMenu"
        >
          <span class="body-2 flex w-full items-center px-16 py-20">
            <IconSwap class="mr-20 h-20 w-20 md:h-24 md:w-24" />

            {{ i18n(translations.mySales) }}
          </span>
        </RevLink>
      </li>

      <li class="border-static-default-low">
        <RevButtonBase
          class="cursor-pointer appearance-none border-none bg-transparent text-inherit no-underline hover:bg-transparent hover:text-inherit hover:no-underline"
          data-test="logout-button"
          @click="handleLogout"
        >
          <span class="body-2 flex w-full items-center px-16 py-20">
            <IconPower class="mr-20 h-20 w-20 md:h-24 md:w-24" />

            {{ i18n(translations.logOut) }}
          </span>
        </RevButtonBase>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref, toRef } from 'vue'

import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useLogout } from '@backmarket/nuxt-layer-oauth/useLogout'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'
import { RevAvatar } from '@ds/components/Avatar'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLink } from '@ds/components/Link'
import { IconAvatar } from '@ds/icons/IconAvatar'
import { IconHeart } from '@ds/icons/IconHeart'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconPower } from '@ds/icons/IconPower'
import { IconSwap } from '@ds/icons/IconSwap'
import { storeToRefs } from 'pinia'

import { ROUTES } from '~/scopes/auth/route-names'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import { useAvatar } from '../composables/useAvatar'

import translations from './UserIconAndSubMenu.translations'

const { trackClick } = useTracking()
const i18n = useI18n()

const userStore = useUserStore()
const authStore = useAuthStore()

const { user } = storeToRefs(userStore)

const { authenticated } = storeToRefs(authStore)

const { logout } = useLogout()

const runtimeConfig = useRuntimeConfig()

const { features } = useMarketplace()

const resolveLocalizedRoute = useRouteLocationWithLocale()

const {
  label: avatarLabel,
  size: avatarSize,
  thumbnail: authIconAvatar,
} = useAvatar(toRef(user.value.firstName))

const { KILL_MY_SALES_ACCESS } = runtimeConfig.public
const isVisible = ref(false)

const hydrationCompleted = ref(false)

onMounted(() => {
  hydrationCompleted.value = true
})

function toggleSubMenu() {
  if (authenticated.value) {
    isVisible.value = !isVisible.value
  }
}
function closeSubMenu() {
  isVisible.value = false
}
function handleClick(name) {
  closeSubMenu()
  trackClick({
    zone: 'header',
    name,
  })
}

function handleLogout() {
  handleClick('subnav_logout')
  logout()
}

const showMySales = computed(() => {
  return features.buyback.enabled && !KILL_MY_SALES_ACCESS
})
const iconAvatarLink = computed(() => ({
  name: ROUTES.AUTH.LOGIN,
  query: {
    next: resolveLocalizedRoute({
      name: DASHBOARD.SELF,
    }).href,
  },
}))
</script>
