export default {
  allOurProductsNew: {
    id: 'header_all_our_products',
    defaultMessage: 'All our products',
  },
  aboutUs: {
    id: 'header_to_about_us',
    defaultMessage: 'About us',
  },
  sellYourTech: {
    id: 'header_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  help: {
    id: 'header_to_help',
    defaultMessage: 'Help',
  },
  verifiedRefurbished: {
    id: 'header_to_verified_refurbished',
    defaultMessage: 'Verified Refurbished',
  },
  backStories: {
    id: 'header_to_back_stories',
    defaultMessage: 'Back Stories',
  },
}
